import matchtitle from '../../components/matchtitle';
import {

	Message
} from 'element-ui'
import {
	handlePageQuerySignupResult,
	handleDeleteSignupPlayer,
	handleAuthSignupPlayers,
	handleQueryTeamOfMatch,
	handleDownloadOnlineSignupTable,
	handleQueryRenamePlayersByMatchId
} from '@index/api/mymatchs/onlinesignuptable';
export default {
	name: 'onlinesignuptable',
	components: {
		matchtitle
	},
	data() {
		return {
			havAuth: false,
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			con: '',
			queryForm: {},
			tableData: [],
			renameTableData: [],
			teams: [],
			matchId: sessionStorage.getItem("currMatchId"),
			sumSignupFee: 0,
			rowIds: [],
			setStateVisible: false,
			stateForm: { state: '0' },
			showRenameDialog: false,
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
		this.queryTeamOfMatch();

	},
	mounted() {

	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		initAuth(havAuth) {
			this.havAuth = havAuth;
			this.havAuth = true;//默认强制开启权限		
		},
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},
		fastQuery() {
			this.currPage = 1;
			this.pageQuery();
		},
		pageQuery() {
			handlePageQuerySignupResult({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con1: this.queryForm.con1,
				con2: this.queryForm.con2,
				state: this.queryForm.state,
				teamId: this.queryForm.teamId,
				matchId: this.matchId

			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
					this.sumSignupFee = res.extendData.sumSignupFee ? res.extendData.sumSignupFee : 0;
					this.$nextTick(() => {
						this.$refs.table.bodyWrapper.scrollTop = 0
					});
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			})
		},
		deleteSignupPlayer(id) { //删除报名选手
			this.$confirm('请确认是否删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				confirmButtonClass: 'confirmButtonClass'
			}).then(() => {
				handleDeleteSignupPlayer(id).then(res => {
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.pageQuery();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				});

			})

		},
		selectRows(selection) {
			if (selection && selection.length > 0) {
				this.rowIds = selection.map(item => item.id);
			} else {
				this.rowIds = [];
			}


		},
		authSignupPlayers(id, state, isSelectData) {
			var title = ""; //请确认是否全部审核通过？
			if (isSelectData && isSelectData == 'selectData') {
				if (!this.rowIds || this.rowIds.length <= 0) {
					this.$message({
						type: 'error',
						message: '请选中数据!'
					});
					return;
				} else {
					title = (state == '1') ? '请确认是否审核通过?' : '请确认是否取消审核?';
				}
			} else if (!id) {
				title = (state == '1') ? '请确认是否全部审核通过?' : '请确认是否全部取消审核?';
			} else {
				title = (state == '1') ? '请确认是否审核通过?' : '请确认是否取消审核?';
			}
			this.$confirm(title, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				confirmButtonClass: 'confirmButtonClass'
			}).then(() => {
				handleAuthSignupPlayers({
					state: state,
					id: id,
					matchId: this.matchId,
					ids: (isSelectData && isSelectData == 'all') ? null : this.rowIds,
					teamId: this.queryForm && this.queryForm.teamId ? this.queryForm.teamId : '',
					con: '',
					con1:this.queryForm.con1,
					con2:this.queryForm.con2,
					queryState:this.queryForm.state,
				}).then(res => {
					if (res.status == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						});
						this.pageQuery();
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				});

			})
		},
		queryTeamOfMatch() { //查询比赛的代表队
			handleQueryTeamOfMatch(this.matchId).then(res => {
				if (res.status == 200) {
					this.teams = res.data;
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		download() {//下载在线报名表
			this.queryForm.matchId = this.matchId;
			handleDownloadOnlineSignupTable(this.queryForm).then(res => {
				var fileName = "在线报名表.xls";
				var blob = new Blob([res], {
					type: "application/octet-stream"
				});
				if (window.navigator.msSaveOrOpenBlob) { //msSaveOrOpenBlob方法返回bool值		
					navigator.msSaveBlob(blob, fileName); //本地保存
				} else {

					var link = document.createElement('a'); //a标签下载
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName;
					link.click();
					window.URL.revokeObjectURL(link.href);
				}
			});

		},
		openAuthDialog(id, state) {
			this.setStateVisible = true;
			this.stateForm = { id: id, state: state };
			console.log(">>>>>" + id + "   " + state);
		},
		submitStateForm() {			
			handleAuthSignupPlayers({
				state: this.stateForm.state,
				id: this.stateForm.id,
				matchId: this.matchId,
				ids: null,
				
			}).then(res => {
				if (res.status == 200) {
					this.setStateVisible = false;
					this.$message({
						type: 'success',
						message: res.msg
					});
					this.pageQuery();
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					});
				}
			});
		},
		viewRenameEvent() {
			this.showRenameDialog = true;			
			handleQueryRenamePlayersByMatchId(this.matchId).then(res => {
				this.renameTableData = res.data;
			})
		}

	}
}